import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'

const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '6+ months'},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: 'Example'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: 'Example'},
]

export default data;