import AboutImage from '../../assets/about.png'
import CV from '../../assets/abbie-morgan-cv.pdf'
import {HiDownload} from 'react-icons/hi'
import aboutdata from './aboutdata'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        {/* Left Side - image */}
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="list of web development skills"/>
          </div>
        </div>
        {/* Right side - info */}
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {
              aboutdata.map(item => (
                <Card key={item.id} className="about__card">
                  <span className='about__card-icon'>{item.icon}</span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }
          </div>
          <p>
            Building projects my clients love has always been my passion.
            I aim to create modern, intuitive and professional websites that feel good to use as I continue to improve my skills, I'm always motivated to do more!
          </p>
          <p>
            Hi, my name is Abbie Morgan from Wales, UK. I'm a web 
            developer with a passion for computer science & design. My top 
            priority is to provide cost-effective web development services, giving you 
            industry-standard design and all the functionality you need to 
            operate smoothly online. Get in touch today with the details of your 
            project - let's get started! Check out my CV below!
          </p>
          <a href={CV} download className='btn primary'>Download CV<HiDownload/></a>
        </div>
      </div>
    </section>
  )
}

export default About