import contactdata from './contactdata'
import './contact.css'

const Contact = () => {
  return (
    <section id="contact">
      {/* Header & subheader */}
      <h2>Get In Touch</h2>
      <p>
        Shoot me a message via the link below!
      </p>
      {/* Links */}
      <div className="container contact__container" data-aos="fade-up">
        {
          contactdata.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener norefferer">{contact.icon}</a>)
        }
      </div>
    </section>
  )
}

export default Contact