import Modal from "../components/Modal"
import { primaryColors,backgroundColors } from "./themedata"
import PrimaryColor from "./PrimaryColor"
import BackgroundColor from "./BackgroundColor"
import './theme.css'

const Theme = () => {
  return (
    <Modal className="theme__modal">
        {/* Card header & subheader */}
        <h3>Customise Your Theme</h3>
        <small>Change the primary and background colour to your preference.</small>
        {/* Change primary colour */}
        <div className="theme__primary-wrapper">
            <h5>Primary Colour</h5>
            <div className="theme__primary-colors">
                {
                    primaryColors.map(pColor => <PrimaryColor key={pColor.className} className={pColor.className}/>)
                }
            </div>
        </div>
        {/* Change background colour */}
        <div className="theme__background-wrapper">
            <h5>Background Colour</h5>
            <div className="theme__background-colors">
                {
                    backgroundColors.map(bColor => <BackgroundColor key={bColor.className} className={bColor.className}/>)
                }
            </div>
        </div>
    </Modal>
  )
}

export default Theme