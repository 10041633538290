import { useEffect } from 'react'
import HeaderImage from '../../assets/header.jpg'
import headerdata from './headerdata'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

const Header = () => {
  // AOS function
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
        {/* Profile Image */}
        <div className="header__profile" data-aos="fade-in">
          <img src={HeaderImage} alt="Header portrait"/>
        </div>
        {/* Name */}
        <h3 data-aos="fade-up">Abbie Morgan</h3>
        {/* Description */}
        <p data-aos="fade-up">
          I'm a web developer with a passion for computer science & design. Send me the details of your 
          project for a modern, mobile responsive & highly performing website today!
        </p>
        {/* Call to action buttons */}
        <div className="header__cta" data-aos="fade-up">
          <a href="#portfolio" className='btn light'>My Work</a>
          <a href="#contact" className='btn primary'>Let's Talk</a>
        </div>
        {/* Social Media buttons */}
        <div className="header__socials">
          {
            headerdata.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header