import ProjectCategories from './ProjectCategories'
import Projects from './Projects'
import portfoliodata from './portfoliodata'
import { useState } from 'react'
import './portfolio.css'

const Portfolio = () => {
  // Filter projects function
  const [projects, setProjects] = useState(portfoliodata);

  const categories = portfoliodata.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];

  const filterProjectsHandler = (category) => {
    if(category === "all") {
      setProjects(portfoliodata);
      return;
    }
    const filterProjects = portfoliodata.filter(project => project.category === category);
    setProjects(filterProjects);
  }

  return (
    <section id="portfolio">
      {/* Header & subheader */}
      <h2>Recent Projects</h2>
      <p>
        Check out some of the projects I've recently worked on. 
        Use the buttons to toggle the different categories.
      </p>
      {/* Portfolio */}
      <div className="container portfolio__container">
        <ProjectCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={projects}/>
      </div>
    </section>
  )
}

export default Portfolio