const data = [
    {
        id: 1,
        question: "What do you need to start working on my project?",
        answer: "I'll need a few key pieces of information from you. This helps me ensure that we're aligned with your vision and can create a tailored solution that meets your needs. I suggest a brief call to discuss the specifics of your requirements, your goals, and any preferences you have.",
    },
    {
        id: 2,
        question: "How long will my project take to complete?",
        answer: "This depends on the complexity of the project, your availability, and your budget. Feel free to contact me and schedule a brief call to discuss your requirements in more depth. This will help me gather all the necessary information to give you the most precise timeline possible.",
    },
    {
        id: 3,
        question: "What's your payment plan?",
        answer: "In order to block off my schedule, I need a deposit before starting on any work. I typically do a 50% deposit to get started, and the final invoice is due before delivery.",
    },
    {
        id: 4,
        question: "How much do you charge for an average website or app?",
        answer: "I don't have a set rate per se just because there are so many factors that could produce a pretty wide array of budgets. Feel free to get in contact so I can understand your goals and pin down a quote for you.",
    },
    {
        id: 5,
        question: "Is hosting and domain registration inclusive in your pricing?",
        answer: "No, hosting and domain is taken care of separately. However, I can help to point you in the right direction to find what you need.",
    },
    {
        id: 6,
        question: "What happens if I'm not satisfied with the final product?",
        answer: "My pricing includes 2 revisions and you'll be apart of the process from the start. I'm happy to continue tweaking until it's just right! I switch to hourly billing after the 2 revisions.",
    },
]

export default data