import {HiOutlineMail} from 'react-icons/hi'
import {RiMessengerLine} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:abbiem6210@gmail.com'},
    // {id: 2, icon: <RiMessengerLine/>, link: ''},
    // {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/07534934076'},
]

// alternative whatsapp link
// https://wa.me/yournumber

export default data