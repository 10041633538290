import servicesdata from './servicesdata'
import Card from '../../components/Card'
import './services.css'

const Services = () => {
  return (
    <section id="services">
      {/* Header & subheader */}
      <h2>My Services</h2>
      <p>Here are the services that I offer:</p>
      {/* Services info */}
      <div className="container services__container" data-aos="fade-up">
        {
        servicesdata.map(item => (
          <Card key={item.id} className="services light">
            <div className="services__icon">{item.icon}</div>
            <div className="services__details">
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </Card>
        ))
        }
      </div>
    </section>
  )
}

export default Services