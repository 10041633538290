import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'Landing Page',
        image: Image1,
        title: "Project Title One (Landing Page)",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
        demo: '',
        github: '',
    },

    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Project Title Two (Frontend)",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
        demo: '',
        github: '',
    },

    // {
    //     id: 3,
    //     category: 'frontend',
    //     image: Image3,
    //     title: "Project Title Three (Frontend)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

    // {
    //     id: 4,
    //     category: 'uiux',
    //     image: Image4,
    //     title: "Project Title Four (UIUX)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

    // {
    //     id: 5,
    //     category: 'uiux',
    //     image: Image5,
    //     title: "Project Title Five (UIUX)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

    // {
    //     id: 6,
    //     category: 'frontend',
    //     image: Image6,
    //     title: "Project Title Six (Frontend)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

    // {
    //     id: 7,
    //     category: 'frontend',
    //     image: Image7,
    //     title: "Project Title Seven (Frontend)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

    {
        id: 8,
        category: 'backend',
        image: Image8,
        title: "Project Title Three (Backend)",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
        demo: '',
        github: '',
    },

    // {
    //     id: 9,
    //     category: 'frontend',
    //     image: Image9,
    //     title: "Project Title Nine (Frontend)",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sem urna, et porttitor nunc tincidunt in. Ut sem enim, malesuada at rutrum sit amet, tempus non orci.",
    //     demo: '',
    //     github: '',
    // },

]

export default data