import {CgWebsite} from 'react-icons/cg'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'

const data = [
    {id: 1, icon: <CgWebsite/>, title: 'Landing Pages', desc: 'My designs are modern and intuitive. I use industry-standard rules to help you convert visitors into leads.'},
    {id: 2, icon: <RiReactjsLine/>, title: 'Front-end Development', desc: 'Your product will look good and will be accessible on all devices, including mobile phones, tablets and desktops.'},
    {id: 3, icon: <FaServer/>, title: 'Back-end Development', desc: 'The security of your business is taken seriously right from the start of the project. I will make sure your website is secure and performs correctly.'},
    {id: 4, icon: <AiFillAppstore/>, title: 'Website Templates', desc: 'I offer a range of customizable, affordable templates in different styles to suit your needs.'},
]

export default data;