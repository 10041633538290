import Logo from '../../assets/logo.png'
import navdata from './navdata'
import {VscSymbolColor} from 'react-icons/vsc'
import {useModalContext} from '../../context/modal-context'
import './navbar.css'

const Navbar = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav>
      <div className="container nav__container">
        {/* Logo */}
        <a href="index.html" className='nav__logo'>
          <img src={Logo} alt="Logo"/>
        </a>
        {/* Nav links */}
        <ul className='nav__menu'>
          {
            navdata.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
          }
        </ul>
        {/* Theme icon */}
        <button id='theme__icon' onClick={showModalHandler}><VscSymbolColor/></button>
      </div>
    </nav>
  )
}

export default Navbar